import { default as indexzu3srDCm4pMeta } from "/home/cyburstud/developer/github/cubpp/mileage.autos/pages/index.vue?macro=true";
export default [
  {
    name: indexzu3srDCm4pMeta?.name ?? "index",
    path: indexzu3srDCm4pMeta?.path ?? "/",
    children: [],
    meta: indexzu3srDCm4pMeta,
    alias: indexzu3srDCm4pMeta?.alias || [],
    redirect: indexzu3srDCm4pMeta?.redirect || undefined,
    component: () => import("/home/cyburstud/developer/github/cubpp/mileage.autos/pages/index.vue").then(m => m.default || m)
  }
]