
import { defuFn } from '/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyBJOCfGT1me0EvWrnvJe2sRcEaIe_5OnkY",
    "authDomain": "mileage-autos-b606b.firebaseapp.com",
    "projectId": "mileage-autos-b606b",
    "storageBucket": "mileage-autos-b606b.appspot.com",
    "messagingSenderId": "518688530988",
    "appId": "1:518688530988:web:e592611370a7447e9fe789",
    "measurementId": "G-Z1CH25EPYV"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "config": {
      "apiKey": "AIzaSyBJOCfGT1me0EvWrnvJe2sRcEaIe_5OnkY",
      "authDomain": "mileage-autos-b606b.firebaseapp.com",
      "projectId": "mileage-autos-b606b",
      "storageBucket": "mileage-autos-b606b.appspot.com",
      "messagingSenderId": "518688530988",
      "appId": "1:518688530988:web:e592611370a7447e9fe789",
      "measurementId": "G-Z1CH25EPYV"
    }
  }
}



export default defuFn(inlineConfig)
