import components_plugin_KR1HBZs4kY from "/home/cyburstud/developer/github/cubpp/mileage.autos/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugin_hk8DoX26Pk from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt-schema-org/dist/runtime/plugin.mjs";
import plugin_client_mik7N71GmK from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_bee5766e_KuK6KJxj64 from "/home/cyburstud/developer/github/cubpp/mileage.autos/.nuxt/templates.plugin.bee5766e.ejs";
import plugin_BEnDUEy4ze from "/home/cyburstud/developer/github/cubpp/mileage.autos/node_modules/nuxt-unhead/dist/runtime/plugin.mjs";
import vue_gtag_client_ts_ghMd2KrBTz from "/home/cyburstud/developer/github/cubpp/mileage.autos/plugins/vue-gtag.client.ts.ts";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  plugin_hk8DoX26Pk,
  plugin_client_mik7N71GmK,
  templates_plugin_bee5766e_KuK6KJxj64,
  plugin_BEnDUEy4ze,
  vue_gtag_client_ts_ghMd2KrBTz
]